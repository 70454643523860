exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-us-js": () => import("./../../../src/pages/about-us.js" /* webpackChunkName: "component---src-pages-about-us-js" */),
  "component---src-pages-blog-js": () => import("./../../../src/pages/blog.js" /* webpackChunkName: "component---src-pages-blog-js" */),
  "component---src-pages-cancellation-policy-js": () => import("./../../../src/pages/cancellation-policy.js" /* webpackChunkName: "component---src-pages-cancellation-policy-js" */),
  "component---src-pages-careers-js": () => import("./../../../src/pages/careers.js" /* webpackChunkName: "component---src-pages-careers-js" */),
  "component---src-pages-cloud-based-services-js": () => import("./../../../src/pages/cloud-based-services.js" /* webpackChunkName: "component---src-pages-cloud-based-services-js" */),
  "component---src-pages-cloud-computing-franchisee-js": () => import("./../../../src/pages/cloud-computing-franchisee.js" /* webpackChunkName: "component---src-pages-cloud-computing-franchisee-js" */),
  "component---src-pages-contact-us-js": () => import("./../../../src/pages/contact-us.js" /* webpackChunkName: "component---src-pages-contact-us-js" */),
  "component---src-pages-contact-us-thankyou-js": () => import("./../../../src/pages/contact-us/thankyou.js" /* webpackChunkName: "component---src-pages-contact-us-thankyou-js" */),
  "component---src-pages-data-center-js": () => import("./../../../src/pages/data-center.js" /* webpackChunkName: "component---src-pages-data-center-js" */),
  "component---src-pages-faq-js": () => import("./../../../src/pages/faq.js" /* webpackChunkName: "component---src-pages-faq-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-media-js": () => import("./../../../src/pages/media.js" /* webpackChunkName: "component---src-pages-media-js" */),
  "component---src-pages-news-events-js": () => import("./../../../src/pages/news-events.js" /* webpackChunkName: "component---src-pages-news-events-js" */),
  "component---src-pages-platform-services-js": () => import("./../../../src/pages/platform-services.js" /* webpackChunkName: "component---src-pages-platform-services-js" */),
  "component---src-pages-pletform-services-js": () => import("./../../../src/pages/pletform-services.js" /* webpackChunkName: "component---src-pages-pletform-services-js" */),
  "component---src-pages-privacy-policy-js": () => import("./../../../src/pages/privacy-policy.js" /* webpackChunkName: "component---src-pages-privacy-policy-js" */),
  "component---src-pages-refund-policy-js": () => import("./../../../src/pages/refund-policy.js" /* webpackChunkName: "component---src-pages-refund-policy-js" */),
  "component---src-pages-search-js": () => import("./../../../src/pages/search.js" /* webpackChunkName: "component---src-pages-search-js" */),
  "component---src-pages-sitemap-html-js": () => import("./../../../src/pages/sitemap.html.js" /* webpackChunkName: "component---src-pages-sitemap-html-js" */),
  "component---src-pages-terms-conditions-js": () => import("./../../../src/pages/terms-conditions.js" /* webpackChunkName: "component---src-pages-terms-conditions-js" */),
  "component---src-templates-blog-js": () => import("./../../../src/templates/blog.js" /* webpackChunkName: "component---src-templates-blog-js" */),
  "component---src-templates-service-js": () => import("./../../../src/templates/service.js" /* webpackChunkName: "component---src-templates-service-js" */)
}

